import { ChatChannelMessageActionsFragment$key } from "@components/chat/channel/message-actions/__generated__/ChatChannelMessageActionsFragment.graphql"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import {
  isUserMuted,
  MESSAGE_ACTIONS,
  useChannelActionContext,
  useChatContext,
  useMessageContext,
} from "stream-chat-react"

interface Props {
  chatChannelKey: ChatChannelMessageActionsFragment$key
}

const ChatChannelMessageActions = ({ chatChannelKey }: Props) => {
  const { mutes, client: stream } = useChatContext()
  const { customMessageActions, getMessageActions, handleMute, handleEdit, message } =
    useMessageContext()
  const { setQuotedMessage } = useChannelActionContext()
  const messageActions = getMessageActions()
  const isMuted = useCallback(() => isUserMuted(message, mutes), [message, mutes])

  const chatChannel = useFragment<ChatChannelMessageActionsFragment$key>(
    graphql`
      fragment ChatChannelMessageActionsFragment on ChatChannel {
        id
        ...usePermissionsFragment
      }
    `,
    chatChannelKey
  )

  const theme = useTheme()
  const permissions = usePermissions(chatChannel)

  function handleDeleteMessage() {
    // We hard delete the message when there are no replies
    stream.deleteMessage(message.id, !message.reply_count)
  }

  const isViewersMessage = message.user?.id === stream.user?.id

  if (!messageActions.length && !customMessageActions) return null

  return (
    <DiscoMoreActionsDropdown
      testid={"ChatChannelMessageActions.more-actions"}
      top={theme.spacing(3.5)}
      rotateIcon
    >
      {messageActions.includes(MESSAGE_ACTIONS.quote) &&
        !message.parent_id &&
        !message.quoted_message && (
          <DiscoDropdownItem title={"Quote reply"} onClick={handleQuote} />
        )}

      {messageActions.includes(MESSAGE_ACTIONS.mute) &&
        permissions.has("chat.manage") && (
          <DiscoDropdownItem onClick={handleMute} title={isMuted() ? "Unmute" : "Mute"} />
        )}

      {/* Only the message creator can edit */}
      {messageActions.includes(MESSAGE_ACTIONS.edit) && isViewersMessage && (
        <DiscoDropdownItem onClick={handleEdit} title={"Edit"} />
      )}
      {messageActions.includes(MESSAGE_ACTIONS.delete) &&
        (isViewersMessage || permissions.has("chat.manage")) && (
          <DiscoDropdownItem onClick={handleDeleteMessage} title={"Delete"} />
        )}
    </DiscoMoreActionsDropdown>
  )

  function handleQuote() {
    setQuotedMessage(message)

    const elements = document.getElementsByClassName("str-chat__textarea__textarea")
    const textarea = elements.item(0)

    if (textarea instanceof HTMLTextAreaElement) {
      textarea.focus()
    }
  }
}

export default ChatChannelMessageActions
