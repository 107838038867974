import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import ChatChannelEmpty from "@components/chat/channel/empty/ChatChannelEmpty"
import { ChatChannelPinnedMessagesListFragment$key } from "@components/chat/channel/pinned-messages/__generated__/ChatChannelPinnedMessagesListFragment.graphql"
import { graphql, useFragment } from "react-relay"
import { MessageList, useChannelStateContext } from "stream-chat-react"

interface Props {
  chatChannelKey: ChatChannelPinnedMessagesListFragment$key
}

function ChatChannelPinnedMessagesList({ chatChannelKey }: Props) {
  const classes = useStyles()

  const { pinnedMessages } = useChannelStateContext()

  const chatChannel = useFragment<ChatChannelPinnedMessagesListFragment$key>(
    graphql`
      fragment ChatChannelPinnedMessagesListFragment on ChatChannel {
        id
        ...ChatChannelMessageFragment
      }
    `,
    chatChannelKey
  )

  return (
    <div className={classes.container}>
      {pinnedMessages?.length ? (
        <MessageList
          messages={pinnedMessages}
          Message={(props) => (
            <ChatChannelMessage {...props} chatChannelKey={chatChannel} />
          )}
          disableDateSeparator
          disableQuotedMessages
          hideNewMessageSeparator
          noGroupByUser
        />
      ) : (
        <ChatChannelEmpty state={"pinned"} />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1),

    "& .str-chat__list .str-chat__reverse-infinite-scroll": {
      padding: 0,
    },

    "& .str-chat__li--single": {
      margin: 0,
    },
  },
}))

export default ChatChannelPinnedMessagesList
