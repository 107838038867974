import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIText from "@components/ai/AIText"
import classNames from "classnames"

interface Props {
  name: string
  className?: string
}

function AITag({ name, className }: Props) {
  const classes = useStyles()
  return (
    <div className={classNames(classes.container, className)}>
      <AIText variant={"body-xs-600"}>{name}</AIText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    borderRadius: theme.measure.borderRadius.default,
    padding: theme.spacing(0, 1),
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.bluePurpleLight,
  },
}))

export default AITag
