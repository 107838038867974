/**
 * @generated SignedSource<<ae1745501e83bc2febce47ec050aed9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelEditMessageFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelMessageOptionsFragment">;
  readonly " $fragmentType": "ChatChannelEditMessageFragment";
};
export type ChatChannelEditMessageFragment$key = {
  readonly " $data"?: ChatChannelEditMessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelEditMessageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelEditMessageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelMessageOptionsFragment"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};

(node as any).hash = "88ff6f9f27319ef342d76d3815ccb8b2";

export default node;
