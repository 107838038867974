import { useBotThreadSuggestion } from "@/chat/channel/BotSuggestionsContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import {
  extractContentUsageId,
  extractOccurrenceId,
  isOrganizationLandingPageUrl,
  isOrganizationLevelRoute,
  isProductLevelRoute,
  isProductUrl,
} from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import styleIf from "@assets/style/util/styleIf"
import ChatChannelDeletedMessage from "@components/chat/channel/detail/message/ChatChannelDeletedMessage"
import { MessageText } from "@components/chat/channel/detail/message/ChatChannelMessageText"
import ChatChannelBotSuggestedMessage from "@components/chat/channel/detail/message/suggestion/ChatChannelBotSuggestedMessage"
import { ChatChannelMessageFragment$key } from "@components/chat/channel/detail/message/__generated__/ChatChannelMessageFragment.graphql"
import ChatChannelMessageOptions from "@components/chat/channel/message-options/ChatChannelMessageOptions"
import ChatChannelReactionList from "@components/chat/channel/reaction-list/ChatChannelReactionList"
import { cleanPreviewAttachments } from "@components/chat/util/chatUtils"
import {
  AttachBlockEntity,
  ATTACH_BLOCK_CONFIG,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import { DiscoIcon, DiscoText, DiscoTextButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import classNames from "classnames"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import {
  Attachment as DefaultAttachment,
  Card,
  EditMessageForm as DefaultEditMessageForm,
  MessageContextValue,
  MessageInput,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageUIComponentProps,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react"

export const VALID_ATTACH_BLOCK_TYPES: AttachBlockEntity[] = [
  "occurrence",
  "contentUsage",
  "product",
  "survey",
]

interface MessageWithContextProps extends MessageContextValue {
  /** Whether or not to disable stuff like reacting/reply in thread when you hover over the message */
  disableOptions?: boolean
  chatChannelKey: ChatChannelMessageFragment$key
}

function MessageWithContext(props: MessageWithContextProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    handleAction,
    handleOpenThread,
    handleRetry,
    message,
    threadList,
    firstOfGroup,
    chatChannelKey,
  } = props

  const {
    Attachment = DefaultAttachment,
    EditMessageInput = DefaultEditMessageForm,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageTimestamp = DefaultMessageTimestamp,
  } = useComponentContext()
  const isFailedMessage = message.status === "failed"
  const isPinnedMessage = Boolean(message.pinned)
  const { isInDrawer, closeDrawer } = useDrawerContext()
  const [showOptions, setShowOptions] = useState(false)
  const [messageAttachments, setMessageAttachments] = useState(message.attachments)

  const chatChannel = useFragment<ChatChannelMessageFragment$key>(
    graphql`
      fragment ChatChannelMessageFragment on ChatChannel {
        id
        ...ChatChannelMessageOptionsFragment
      }
    `,
    chatChannelKey
  )

  const classes = useStyles({ isFailedMessage, isPinnedMessage, isInDrawer })
  const theme = useTheme()

  const divRef = useRef<HTMLDivElement | null>(null)

  const { botSuggestion } = useBotThreadSuggestion({
    threadId: message.id,
    // Only get suggestions for main thread messages
    disabled: Boolean(message.parent_id),
  })
  useEffect(() => {
    const cleanedAttachments = cleanPreviewAttachments(message.attachments)
    setMessageAttachments(cleanedAttachments)
  }, [message.attachments])

  if (message.deleted_at || message.type === "deleted") {
    return (
      <ChatChannelDeletedMessage
        message={message}
        threadList={threadList}
        handleReply={handleClickReply}
      />
    )
  }

  // hide preview if link is for active org/product/event/contentUsage, but not org landing page
  const shouldHideDiscoPreview =
    message.attachments?.length === 1 &&
    message.attachments[0].og_scrape_url &&
    (isOrganizationLevelRoute(message.attachments[0].og_scrape_url, {
      orgSlug: activeOrganization.slug,
    }) ||
      isProductLevelRoute(message.attachments[0].og_scrape_url, {
        orgSlug: activeOrganization.slug,
        productSlug: activeProduct?.slug,
      }) ||
      extractOccurrenceId(message.attachments[0].og_scrape_url) !== null ||
      extractContentUsageId(message.attachments[0].og_scrape_url) !== null ||
      isProductUrl(message.attachments[0].og_scrape_url)) &&
    !isOrganizationLandingPageUrl(message.attachments[0].og_scrape_url)

  return (
    <>
      <div
        key={message.id}
        className={classes.container}
        data-testid={"ChatChannelSimpleMessage.container"}
        onClick={handleRetryMessage}
        onMouseEnter={handleShowOptions}
        onMouseLeave={handleHideOptions}
        onKeyDown={handleRetryMessage}
        role={"button"}
        tabIndex={0}
      >
        {/* Pinned Message */}
        {isPinnedMessage && (
          <div className={classes.pinnedContainer}>
            <DiscoIcon
              icon={"pin"}
              active
              color={theme.palette.primary.main}
              paddingRight={1}
              height={24}
              width={24}
            />
            <DiscoText variant={"body-sm-600"} color={"primary.main"}>
              {message.pinned_by ? `Pinned by ${message.pinned_by.name}` : "Pinned"}
            </DiscoText>
          </div>
        )}

        {!editing && (
          <div ref={divRef} className={classes.messageContainer}>
            {message.user && (
              <>
                {firstOfGroup ? (
                  <OpenProfilePopoverButton
                    testid={"ChatChannelMessageAvatar"}
                    className={classes.baseButton}
                    userId={Relay.toGlobalId(
                      "User",
                      message.user.disco_user_id as string
                    )}
                  >
                    <UserAvatar
                      placeholderClassName={"avatar-placeholder"}
                      user={{
                        first_name: message.user.first_name as string | null,
                        last_name: message.user.last_name as string | null,
                        avatar: message.user.avatar as string | null,
                        id: Relay.toGlobalId(
                          "User",
                          message.user.disco_user_id as string
                        ),
                      }}
                      size={32}
                    />
                  </OpenProfilePopoverButton>
                ) : (
                  <UserAvatar
                    placeholderClassName={"avatar-placeholder"}
                    user={{
                      first_name: message.user.first_name as string | null,
                      last_name: message.user.last_name as string | null,
                      avatar: message.user.avatar as string | null,
                      id: Relay.toGlobalId("User", message.user.disco_user_id as string),
                    }}
                    size={32}
                  />
                )}
              </>
            )}
            <div data-testid={"message-inner"} className={classes.message}>
              {/* User */}
              <div className={classNames(classes.nameAndDate, "message__name-and-date")}>
                {message.user?.name ? (
                  <OpenProfilePopoverButton
                    testid={"ChatChannelMessageUserName"}
                    customAnchor={divRef.current}
                    userId={Relay.toGlobalId(
                      "User",
                      message.user.disco_user_id as string
                    )}
                  >
                    {(buttonProps) => (
                      <DiscoTextButton {...buttonProps}>
                        <DiscoText
                          variant={"body-md-600"}
                          display={"inline"}
                          marginRight={1}
                        >
                          {message?.user?.name && message.user.name}
                        </DiscoText>
                      </DiscoTextButton>
                    )}
                  </OpenProfilePopoverButton>
                ) : null}

                <MessageTimestamp
                  format={DATE_FORMAT.SHORT_TIME_FORMAT}
                  customClass={classes.timestamp}
                />
              </div>

              {/* Options */}
              {!props.disableOptions && showOptions && (
                <ChatChannelMessageOptions chatChannelKey={chatChannel} />
              )}

              {/* Attachments */}
              {messageAttachments?.length && !message.quoted_message ? (
                <div>
                  {messageAttachments
                    .filter(
                      (attachment) =>
                        attachment.type &&
                        VALID_ATTACH_BLOCK_TYPES.includes(
                          attachment.type as AttachBlockEntity
                        )
                    )
                    .map((attachment) =>
                      (() => {
                        const config =
                          ATTACH_BLOCK_CONFIG[attachment.type as AttachBlockEntity]
                        const entityId = attachment.text!
                        const component = React.createElement(config.Component, {
                          entityId,
                        })

                        return <Fragment key={entityId}>{component}</Fragment>
                      })()
                    )}

                  {messageAttachments && (
                    <Attachment
                      Card={(cardProps) => {
                        if (shouldHideDiscoPreview) return null
                        return <Card {...cardProps} />
                      }}
                      actionHandler={handleAction}
                      attachments={messageAttachments}
                    />
                  )}
                </div>
              ) : null}

              {/* Message */}
              <MessageText message={message} customInnerClass={classes.messageText} />

              {/* Reactions */}
              <ChatChannelReactionList />

              {/* Replies */}
              {!threadList && !!message.reply_count && (
                <div className={classes.replyContainer}>
                  <MessageRepliesCountButton
                    onClick={(e) => handleClickReply(e)}
                    reply_count={message.reply_count}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {botSuggestion && !editing && (
        <ChatChannelBotSuggestedMessage
          botMessageKey={botSuggestion}
          inThread={threadList}
          threadId={message.id}
        />
      )}
      {editing && (
        <MessageInput
          clearEditingState={clearEditingState}
          Input={EditMessageInput}
          message={message}
          {...additionalMessageInputProps}
        />
      )}
    </>
  )

  function handleShowOptions() {
    setShowOptions(true)
  }

  function handleHideOptions() {
    setShowOptions(false)
  }

  function handleRetryMessage() {
    if (isFailedMessage) {
      handleRetry(message)
    }
  }

  function handleClickReply(event: React.MouseEvent<Element, MouseEvent>) {
    if (closeDrawer) closeDrawer()
    handleOpenThread(event)
  }
}

type StyleProps = {
  isFailedMessage: boolean
  isPinnedMessage: boolean
  isInDrawer: boolean
}

const useStyles = makeUseStyles((theme) => ({
  baseButton: {
    padding: "0px",
  },
  container: ({ isFailedMessage, isPinnedMessage, isInDrawer }: StyleProps) => ({
    display: "flex",
    padding: theme.spacing(1.5),
    outline: "none",
    ...styleIf(isPinnedMessage, {
      backgroundColor: isInDrawer
        ? theme.palette.background.paper
        : theme.palette.type === "dark"
        ? theme.palette.groovy.grey[700]
        : theme.palette.primary.light,
      borderRadius: theme.measure.borderRadius.big,
      flexDirection: "column",
      marginBottom: isInDrawer ? theme.spacing(1) : `${theme.spacing(1)}px !important`,
    }),

    cursor: isFailedMessage ? "pointer" : "default",

    "& .str-chat__avatar": {
      width: "40px",
      height: "40px",
      flex: "0 0 40px",
      marginRight: theme.spacing(2),

      ...styleIf(isPinnedMessage, {
        visibility: "visible",
        height: "40px !important",
      }),
    },

    "& .str-chat__message-mention": {
      color: theme.palette.primary.main,
      fontSize: "15px",
    },
    "& .str-chat__message-text-inner": {
      color: theme.palette.groovy.grey[700],
      padding: 0,
      borderRadius: 0,
      border: "none",
      backgroundColor: "transparent",
      minHeight: "auto",

      "& p": {
        ...theme.typography["body-sm"],
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "none",
      },
    },
    "& .str-chat__message-attachment--img": {
      maxWidth: "300px",
      borderRadius: "16px",
    },
    "& .str-chat__message-attachment": {
      maxWidth: "100%",
    },
    "& .str-chat__gallery": {
      justifyContent: "flex-start",
      maxWidth: "100%",
      flexWrap: "wrap",
      gap: theme.spacing(0.5),

      "&--square": {
        backgroundColor: "transparent",
      },
    },
    "& .str-chat__message-url-link": {
      whiteSpace: "unset",
      wordBreak: "break-all",
    },
    "& .str-chat__message-attachment--card--no-image": {
      height: "unset",
    },
    "& .str-chat__message-attachment-card": {
      marginTop: 0,
      width: "fit-content",
      "&--url": {
        textTransform: "unset",
      },
      "&--header": {
        height: "unset",
        width: "unset",
        maxHeight: "175px",
        "& img": {
          width: "auto",
          height: "auto",
          margin: "auto",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&--image": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  }),
  replyContainer: {
    marginBottom: theme.spacing(1),
  },
  nameAndDate: ({ isPinnedMessage }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1),

    ...styleIf(isPinnedMessage, {
      display: "flex !important",
    }),
  }),
  timestamp: {
    ...theme.typography["body-sm"],
    fontSize: "12px",
    color: theme.palette.groovy.grey[400],
    textTransform: "uppercase",
  },
  message: {
    minWidth: "90%",
    maxWidth: "100%",
    marginLeft: theme.spacing(2),
  },
  messageContainer: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    "& .str-chat__message-attachment": {
      maxHeight: "unset",

      "& .str-chat__message-attachment-card": {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",

        "& .str-chat__message-attachment-card--header img": {
          maxWidth: "100%",
        },
      },
    },
  },
  pinnedContainer: {
    display: "flex",
    padding: theme.spacing(0.5, 0, 1, 0),
    alignItems: "center",
  },
  messageText: {
    "& ul": {
      marginLeft: theme.spacing(2),
    },
    wordBreak: "normal",
    overflowWrap: "anywhere",
    // HTML code tags are wrapped in a <pre></pre> tag which must enforce word wrapping to avoid overflow
    "& > * > pre": {
      whiteSpace: "pre-wrap",
    },
  },
}))

const MemoizedMessage = React.memo(MessageWithContext, (prevProps, nextProps) => {
  return prevProps.threadList !== nextProps.threadList
}) as typeof MessageWithContext

interface ChatChannelMessageProps extends MessageUIComponentProps {
  disableOptions?: MessageWithContextProps["disableOptions"]
  chatChannelKey: ChatChannelMessageFragment$key
}

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
function ChatChannelMessage(props: ChatChannelMessageProps) {
  const messageContext = useMessageContext()

  return <MemoizedMessage {...messageContext} {...props} />
}

export default ChatChannelMessage
