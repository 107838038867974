import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSpinner, DiscoTextButton, DiscoTextButtonProps } from "@disco-ui"

type AITextButtonProps = DiscoTextButtonProps & {
  className?: string
  shouldDisplaySpinner?: boolean
}

function AITextButton(props: AITextButtonProps) {
  const { children, className, shouldDisplaySpinner, leftIcon, ...rest } = props
  const classes = useStyles({ shouldDisplaySpinner })

  const spinnerIcon = shouldDisplaySpinner ? <DiscoSpinner size={"sm"} /> : undefined

  return (
    <DiscoTextButton
      {...rest}
      className={className}
      classes={{
        root: classes.root,
      }}
      leftIcon={spinnerIcon || leftIcon}
      leftIconClassname={classes.icon}
      rightIconClassname={classes.icon}
    >
      {children}
    </DiscoTextButton>
  )
}

type StyleProps = {
  shouldDisplaySpinner?: boolean
}

const useStyles = makeUseStyles((theme) => {
  return {
    root: ({ shouldDisplaySpinner }: StyleProps) => ({
      padding: theme.spacing(0.5),
      backgroundImage: shouldDisplaySpinner
        ? "none"
        : theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurple02
        : theme.palette.aiGradient.bluePurple03,
      backgroundClip: "text",
      textFillColor: shouldDisplaySpinner ? theme.palette.grey[700] : "transparent",
    }),
    icon: {
      "& svg": {
        color: theme.palette.groovy.blue[300],
      },
    },
  }
})

export default AITextButton
